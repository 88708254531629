.apexcharts-canvas {
	@if $enable-rtl {
		direction: ltr;
		text-align: left;
	}
	& .apexcharts-menu {
		border: none;
		padding: $dropdown-padding-y 0;
		background: var(--app-component-dropdown-bg);
		
		@include border-radius($border-radius);
		@include box-shadow($box-shadow);
		
		& .apexcharts-menu-item {
			&:hover,
			&:focus {
				background: $dropdown-link-hover-bg;
			}
		}
	}
	& .apexcharts-toolbar {
		& > div {
			& > svg {
				fill: #{rgba(var(--app-component-color-rgb), .5)};
			}
			&:hover,
			&:focus {
				& > svg {
					fill: #{rgba(var(--app-component-color-rgb), .75)};
				}
			}
		}
	}
	& .apexcharts-tooltip {
		border: none !important;
		font-size: inherit;
		background: var(--app-component-modal-bg) !important;
		color: var(--app-component-color) !important;
		
		@include box-shadow($dropdown-box-shadow);
		
		& .apexcharts-tooltip-title {
			font-family: inherit !important;
			padding: $spacer * .4 $spacer * .666;
			margin: 0;
			border: 0 !important;
			font-weight: $font-weight-bold;
			background: var(--bs-light) !important;
		}
		& .apexcharts-tooltip-marker {
			width: rem(8px);
			height: rem(8px);
			
			@if $enable-rtl {
				margin-left: $spacer * .5;
			} @else {
				margin-right: $spacer * .5;
			}
		}
		& .apexcharts-tooltip-series-group {
			padding-left: $spacer * .666;
			padding-right: $spacer * .666;
			padding-bottom: 0;
			
			& .apexcharts-tooltip-y-group {
				padding: $spacer * .4 0;
			}
			& + .apexcharts-tooltip-series-group {
				margin-top: $spacer * -.4;
			}
			&.active, 
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
	& .apexcharts-xaxistooltip {
		background: var(--app-component-modal-bg);
		border-color: var(--app-component-border-color);
		color: var(--app-component-color);
		
		&.apexcharts-xaxistooltip-top {
			&:after {
				border-top-color: var(--app-component-modal-bg);
			}
			&:before {
				border-top-color: var(--app-component-border-color);
			}
		}
		&.apexcharts-xaxistooltip-bottom {
			&:after {
				border-bottom-color: var(--app-component-modal-bg);
			}
			&:before {
				border-bottom-color: var(--app-component-border-color);
			}
		}
	}
	& .apexcharts-yaxistooltip {
		background: var(--app-component-modal-bg);
		border-color: var(--app-component-border-color);
		color: var(--app-component-color);
		
		&.apexcharts-yaxistooltip-left {
			&:after {
				border-left-color: var(--app-component-modal-bg);
			}
			&:before {
				border-left-color: var(--app-component-border-color);
			}
		}
		&.apexcharts-yaxistooltip-right {
			&:after {
				border-right-color: var(--app-component-modal-bg);
			}
			&:before {
				border-right-color: var(--app-component-border-color);
			}
		}
	}
	& .apexcharts-legend {
		body & {
			& .apexcharts-legend-series {
				@include display-flex();
				@include flex-align(center);
	
				& .apexcharts-legend-marker {
					margin-right: $spacer * .5;
					width: rem(8px) !important;
					height: rem(8px) !important;
				
					@include border-radius(12px !important);
				}
			}
		}
	}
}