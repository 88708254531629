.todolist {
	list-style-type: none;
	margin: 0;
	padding: 0;
	
	& .todolist-item {
		@include display-flex();
		
		& .todolist-input {
			padding: $spacer * 0.5 $spacer * 0.75;
			
			@include display-flex();
			@include flex-align(center);
			
			& .form-check {
				padding-left: rem(18px);
				margin: 0;
				
				& .form-check-input {
					width: rem(18px);
					margin-left: rem(-18px);
					
					&:checked {
						border-color: var(--bs-success);
						background-color: var(--bs-success);
					}
				}
			}
			& + .todolist-label {
				@if $enable-rtl {
					border-right: 1px solid var(--app-component-border-color);
				} @else {
					border-left: 1px solid var(--app-component-border-color);
				}
			}
		}
		& .todolist-label {
			padding: $spacer * 0.5 $spacer * 0.75;
			
			@include flex(1);
		}
		&:hover {
			background: var(--bs-light);
		}
		&.active {
			background: rgba(var(--bs-success-rgb), .15);
			
			& .todolist-label {
				text-decoration: line-through;
			}
		}
		
		& + .todolist-item {
			border-top: 1px solid var(--app-component-border-color);
		}
	}
}