.note {
	margin-bottom: rem(20px);
	position: relative;
	
	@if $enable-rtl {
		border-right: rem(3px) solid;
	} @else {
		border-left: rem(3px) solid;
	}
	@include border-radius($border-radius);
	@include display-flex();
	@include flex(1);
	
	& .note-icon {
		width: rem(80px);
		font-size: rem(56px);
		
		@include display-flex();
		@include flex-align(center);
		@include flex-justify-content(center);
	}
	& .note-content {
		padding: rem(15px);
		@include flex(1);
		
		& h1, 
		& h2, 
		& h3, 
		& h4, 
		& h5, 
		& h6 {
			color: inherit;
		}
	
	}
	&.note-with-end-icon {
		@if $enable-rtl {
			border-right: none;
			border-left: rem(3px) solid;
		} @else {
			border-left: none;
			border-right: rem(3px) solid;
		}
	}
	
	@each $color, $value in $theme-colors {
		&.note-#{$color} {
			
			@if $color == 'primary' {
				background: lighten($blue, 10%);
				color: darken($blue, 30%);
			}
			@if $color == 'light' {
				background: $white;
				color: $dark;
			}
			@if $color != 'primary' and $color != 'light' {
				border-color: $value;
				background: lighten($value, 10%);
				color: darken($value, 30%);
			}
		}
	}
	@each $color, $value in $colors {
		&.note-#{$color} {
			border-color: $value;
			background: lighten($value, 15%);
		}
	}
}