.wysihtml5-sandbox {
	width: 100% !important;
	border: none !important;
	padding: $spacer !important;
	
	@include border-radius(0 !important);
}
ul.wysihtml5-toolbar {
	body & {
		padding: $spacer $spacer $spacer * .5 !important;
		border-bottom: 1px solid #{rgba(var(--app-component-border-color-rgb), .5)};
		background: #{rgba(var(--bs-light-rgb), .75)};
		
		@include display-flex();
		@include flex-wrap(wrap);
		
		& > li {
			float: none;
			margin: 0 $spacer * .5 $spacer * .5 0;
		}
		& .btn.btn-default {
			background: var(--app-component-bg);
			border-color: var(--app-component-border-color);
			
			&:hover,
			&:focus {
				background: var(--app-component-hover-bg);
			}
			
			&.wysihtml5-command-active {
				background: var(--app-component-active-bg);
				border-color: var(--app-component-active-bg);
				color: var(--app-component-active-color);
				
				@include box-shadow(none);
			}
		}
		&	.glyphicon {
			@include fontawesome();
		
			&.glyphicon-quote:before { display: none; }
			&.glyphicon-quote:after { content: '\f10d'; }
			&.glyphicon-list:before { content: '\f03a'; }
			&.glyphicon-indent-right:before { content: '\f038'; }
			&.glyphicon-indent-left:before { content: '\f036'; }
			&.glyphicon-th-list:before { content: '\f00b'; }
			&.glyphicon-share:before { content: '\f064'; }
			&.glyphicon-picture:before { content: '\f03e'; }
		}
		& .dropdown-menu {
			list-style-type: none;
			margin: 0;
			padding: $dropdown-padding-y $dropdown-padding-x;
			
			& > li {
				& > a {
					display: block;
					padding: $dropdown-item-padding-y $dropdown-item-padding-x;
					color: $dropdown-link-color;
					text-decoration: none;
					
					&:hover,
					&:focus {
						background: $dropdown-link-hover-bg;
						color: $dropdown-link-hover-color;
					}
				}
			}
		}
	}
}