.widget-input-container {
	@include display-flex();
	@include flex-align(center);
	
	& .widget-input-icon {
		padding: 0 $spacer * .333;
		
		& a {
			display: block;
			padding: $spacer * 0.66;
			font-size: rem(18px);
		}
	}
	& .widget-input-box {
		padding: $spacer * .4 0;
		
		@include flex(1);
		
		& .form-control {
			border-color: var(--bs-light);
			background: var(--bs-light);
			
			@include border-radius($border-radius * 10);
			
			&:focus {
				@include box-shadow(none);
			}
		}
	}
	& .widget-input-divider {
		width: rem(1px);
		
		&:after {
			content: '';
			width: rem(1px);
			height: rem(26px);
			background: var(--app-component-border-color);
			display: block;
		}
	}
}