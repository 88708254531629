@include keyframes(rotation) {
	from { @include transform(rotate(0deg)); }
	to { @include transform(rotate(359deg)); }
}

.spinner {
	height: rem(36px);
	width: rem(36px);
	border: 2px solid $gray-800;
	border-top-color: $white;
	
	@include border-radius(36px);
	@include animation(rotation .6s infinite linear);
	
	&.spinner-sm {
		height: rem(28px);
		width: rem(28px);
	}
}