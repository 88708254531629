.sp-original-input-container {
	& .sp-add-on {
		min-width: rem(35px);
		
		@if $enable-rtl {
			body & {
				@include border-radius($border-radius !important);
				@include border-top-left-radius(0 !important);
				@include border-bottom-left-radius(0 !important);
			}
		}
		
		& .sp-colorize {
			position: relative;
			overflow: hidden;
			
			@include border-radius($border-radius-sm - 2 !important);
			@if $enable-rtl {
				@include border-top-left-radius(0 !important);
				@include border-bottom-left-radius(0 !important);
			} @else {
				@include border-top-right-radius(0 !important);
				@include border-bottom-right-radius(0 !important);
			}
			
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: 5px solid var(--app-component-border-color);
			}
		}
	}
	& .spectrum.with-add-on {
		@if $enable-rtl {
			border-left: 1px solid var(--app-component-border-color);
			border-right: 0;
		
			@include border-radius($border-radius !important);
			@include border-top-right-radius(0 !important);
			@include border-bottom-right-radius(0 !important);
		}
	}
}
.sp-container {
	body & {
		background-color: var(--app-component-dropdown-bg);
		
		& .sp-palette-container {
			border-color: var(--app-component-dropdown-border-color);
		}
		& .sp-input {
			border-color: var(--app-component-dropdown-border-color) !important;
			color: var(--app-component-color) !important;
		}
		& button {
			&.sp-choose {
				background-color: var(--bs-success);
			}
			&.sp-cancel {
				background-color: var(--bs-default);
			}
		}
	}
}