.app-sidebar-float-submenu-container {
	position: fixed;
	width: $app-sidebar-width;
	margin: 0;
	background: $gray-900;
	z-index: $app-sidebar-float-submenu-zindex;
	
	& .app-sidebar-float-submenu {
		@if $enable-rtl {
			padding: rem(9px) rem(39px) rem(9px) 0;
		} @else {
			padding: rem(9px) 0 rem(9px) rem(39px);
		}
		
		& .active,
		& .expanding,
		& .expand {
			& > a {
				& .menu-caret {
					&:before {
						opacity: 1.0;
						
						@include transform(rotate(90deg));
						@if $enable-rtl {
							@include transform(rotate(-90deg));
						}
					}
				}
			}
		}
		& .menu-caret {
			display: block;
			margin-left: auto;
			width: rem(20px);
			text-align: center;
			font-size: $font-size-sm;
			border: none;
		
			@include fontawesome();
			@if $enable-rtl {
				margin-right: auto;
			}
		
			&:before {
				content: '\f054';
				display: block;
				text-align: center;
				opacity: 0.5;
			
				@include transition(all .2s linear);
				@if $enable-rtl {
					content: '\f053';
				}
			}
		}
		& .menu-item {
			position: relative;
					
			& .menu-link {
				padding: rem(3px) rem(20px) rem(3px) rem(15px);
				line-height: rem(18px);
				color: rgba($white, .45);
				text-decoration: none;
				position: relative;
				
				@include display-flex();
				@include flex-align(flex-start);
				@if $enable-rtl {
					padding-right: rem(15px);
					padding-left: rem(20px);
				}
				
				& .menu-text {
					@include flex(1);
				}
				& .menu-badge {
					padding: 0 rem(8px);
					background: $gray-900;
					font-weight: 600;
					font-size: rem(10px);
					
					@include border-radius($border-radius-lg * 2);
				}
				& .menu-label {
					padding: rem(3px) rem(6px);
					font-size: 75%;
					font-weight: $font-weight-bold;
					color: $white;
					text-align: center;
					white-space: nowrap;
					vertical-align: baseline;
					margin-left: rem(5px);
					background-color: var(--app-theme);
					
					@include border-radius($border-radius);
					@include gradient-enabled {
						background-image: var(--bs-gradient);
					}
					@if $enable-rtl {
						margin-left: 0;
						margin-right: rem(5px);	
					}
				}
				&:hover,
				&:focus {
					background: none;
					color: $white;
				}
				&:after {
					content: '';
					position: absolute;
					top: rem(11px);
					width: rem(11px);
					height: rem(2px);
					background: $gray-800;
		
					@if $enable-rtl {
						right: rem(-11px);
					} @else {
						left: rem(-11px);
					}
				}
			}
			& .menu-icon {
				width: rem(14px);
				text-align: center;
				line-height: rem(20px);
				font-size: $font-size-lg;
				margin-right: rem(10px);
				
				@if $enable-rtl {
					margin-right: 0;
					margin-left: rem(10px);
				}
				& img {
					max-width: 100%;
					max-height: 100%;
				}
				&[class^="ion-"] {
					margin-right: rem(8px);
					width: rem(18px);
					font-size: rem(18px);
					margin-left: rem(-2px);
					
					@if $enable-rtl {
						margin-right: rem(-2px);
						margin-left: rem(8px);
					}
				}
			}
			& .menu-icon-img {
				margin-right: rem(10px);
				margin-top: rem(3px);
				width: rem(14px);
				height: rem(14px);
				overflow: hidden;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				
				@include border-radius($border-radius);
				@include display-flex();
				@include flex-align(center);
				@if $enable-rtl {
					float: right;
					margin-right: 0;
					margin-left: rem(10px);
				}
				
				& img {
					max-width: 100%;
					max-height: 100%;
					display: block;
					margin: 0 auto;
				}
			}
			
			&:before {
				content: '';
				position: absolute;
				left: rem(-13px);
				top: 0;
				bottom: 0;
				width: rem(2px);
				background: $gray-800;
	
				@if $enable-rtl {
					right: rem(-13px);
					left: auto;
				}
			}
			&:after {
				content: '';
				position: absolute;
				left: 0;
				width: rem(6px);
				height: rem(6px);
				border: 1px solid rgba($white, .3);
				top: rem(11px);
				margin-top: rem(-2px);
				z-index: 10;
				background: $gray-900;
	
				@include border-radius(4px);
				@if $enable-rtl {
					left: auto;
					right: 0;
				}
			}
			&:first-child {
				&:before {
					top: rem(-11px);
				}
				&:last-child:before {
					height: rem(20px);
				}
			}
			&:last-child {
				&:before {
					bottom: auto;
					height: rem(13px);
				}
			}
			&.has-sub {
				& > a {
					&:before {
						background: $gray-900;
					}
				}
			}
			&.active {
				& > .menu-link {
					background: none;
					color: $white;
				}
				&:after {
					border-color: var(--app-theme);
				}
			}
			
			& .menu-submenu {
				padding: 0 0 0 rem(15px);
				background: none;
				position: relative;
				display: none;

				@if $enable-rtl {
					padding-left: 0;
					padding-right: rem(15px);
				}
			}
			
			&.active {
				&.has-sub {
					& > .menu-submenu {
						display: block;
					}
				}
			}
		}
		& > .menu-item {
			&:first-child {
				&:before {
					top: rem(11px);
				}
				&:last-child {
					&:before {
						height: 0 !important;
					}
				}
			}
		}
	}
	& .app-sidebar-float-submenu-arrow {
		position: absolute;
		top: rem(20px);
		width: rem(28px);
		height: rem(2px);
		background: $gray-800;
		
		@if $enable-rtl {
			right: 0;
		} @else {
			left: 0;
		}
	}
	& .app-sidebar-float-submenu-line {
		position: absolute;
		top: rem(20px);
		width: rem(2px);
		background: $gray-800;
		
		@if $enable-rtl {
			right: rem(26px);
		} @else {
			left: rem(26px);
		}
	}
}