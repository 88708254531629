.ui-widget {
	font-family: inherit;
	
	&.ui-autocomplete {
		padding: $spacer * .5 0;
		border: none;
		font-size: $font-size-base;
		background-color: var(--app-component-dropdown-bg);
		
		@include box-shadow($box-shadow);
		@include border-radius(0 0 $border-radius $border-radius);
		
		& .ui-menu-item-wrapper {
			padding: $nav-link-padding-y $nav-link-padding-x;
			font-weight: $font-weight-bold;
			color: $dropdown-link-color;
			
			&.ui-state-active {
				background: var(--app-component-dropdown-hover-bg);
				border: none;
				color: var(--app-component-hover-color);
				margin: 0;
				
				@include border-radius(0);
			}
		}
	}
}